import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ReactPlayer from "react-player";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

import locales from "../../constants";


const BialaPerla = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 0,
  };
  const lang = "pl";

  return (
    <Layout
      seo={{
        title: "Biała Perła",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/bialaperla-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 225deg, #ffcfe1, #fcc0d7, #fab6d0, #eda6c1, #e096b3 )",
        icons: "#fab6d0",
        navClass: "bialaperla",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/biala-perla/",
      }}
    >
      <PortfolioHeader
        name="bialaperla"
        height="70"
        video="https://wp.adream.pl/adream-content/bialaperla-spot.mp4"
      />

      <section className="container-fluid portfolio-intro" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1 style={{ color: "#fab6d0" }}>Biała Perła</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania marketingowa</li>
                <li>Katalog</li>
                <li>Spot reklamowy</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                Dla naszego klienta Czarna Góra Resort zrealizowaliśmy szerg
                projektów realizowanych w ramach nowego projektu inwestycyjnego
                - aparthotelu Biała Perła. Nasza współpraca obejmowała
                zaprojektowanie i zakodowanie nowoczesnej strony internetowej
                inwestycji, stworzenie katalogu, realizację kampanii
                marketingowej, włączając w to stworzenie spotu reklamowego.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--monitor scroll_wrapper--monitor-2">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/bialaperla-full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--1">
        <div className="row">
          <div className="col-md-4">
            <h3>Strona internetowa</h3>
            <p>
              Zaprojektowaliśmy nowoczesną stronę internetową skierowaną do
              potencjalnych inwestorów. Przy okazji realizacji projektu jako
              pierwsi w Polsce stworzyliśmy animowaną wizualizację inwestycji
              aparthotelowej, która wyświetla się na pierwszym ekranie.
            </p>
          </div>
          <div className="offset-md-1 col-md-7">
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-1.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-2.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--2">
        <div className="bialaperla-section__row">
          <div className="bialaperla-section__col">
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-3.png")}
              alt=""
            />
          </div>
          <div className="bialaperla-section__col">
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-4.png")}
              alt=""
            />
          </div>
          <div className="bialaperla-section__col">
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-5.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--3">
        <div className="row">
          <div className="col-md-8 row__col">
            <img
              src={require("../../assets/img/portfolio/bialaperla-img-6.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-3 row__col">
            <div className="inner">
              <h3>Kampania marketingowa</h3>
              <p>
                Nasza agencja marketingu internetowego WHEN prowadziła kampanię
                która w kilkanaście miesięcy przyczyniła się do wyprzedania
                większości apartamentów inwestycyjnych.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--video">
        <div className="content-container">
          <div className="content-container__text">
            <h3>Spot reklamowy i animacja wizualizacji obiektu</h3>
            <p>
              Na bazie statycznej wizualizacji obiektu dostarczonej nam przez
              Klienta stworzyliśmy w pełni animowaną wizualizację oraz spot
              reklamowy do wykorzystania w kampanii promocyjnej skierowanej do
              inwestorów zainteresowanych kupnem apartamentów inwestycyjnych pod
              wynajem.
            </p>
          </div>
          <div className="content-container__video">
            <ReactPlayer
              url="https://wp.adream.pl/adream-content/bialaperla/bialaperla-spot.mp4"
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
          <div className="content-container__animation">
            <ReactCompareSlider
              itemOne={
                <video
                  muted
                  autoPlay="autoplay"
                  loop={true}
                  height="100%"
                  playsInline
                >
                  <source
                    src="https://wp.adream.pl/adream-content/bialaperla/bialaperla-animation.mp4"
                    type="video/mp4"
                  />
                </video>
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={require("../../assets/img/portfolio/bialaperla-animation-frame.jpg")}
                  alt=""
                />
              }
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--4">
        <div className="col-md-4 offset-md-1">
          <h3>Projekt katalogu</h3>
          <p>
            Stworzyliśmy projekt katalogu przedstawiającego wizualizacje
            inwestycji oraz zawierającego wszystkie kluczowe informacje które
            mogłyby zainteresować potencjalnego inwestora.
          </p>
        </div>
        <img
          src={require("../../assets/img/portfolio/bialaperla-img-7.png")}
          alt=""
        />
      </section>

      <BanerMDD />

      <PortfolioNav data={nav} lang={lang} locales={locales[lang]} />
    </Layout>
  );
};
export default BialaPerla;
